import React from "react"
// import Image from "../../components/card/image"
import Layout from "../../components/layout"
import NavAbout from "../../components/nav/about"

const SpeedTest = () => (
    <Layout title="我们的测速方法 - wall101">
    <NavAbout />
    <div id="speedtest" className="about" >
        <div className="title" >
            <div className="container" >
                <h1>我们的测速方法</h1>
                {/* <img className="bg-title" src={Bg} alt="background" /> */}
            </div>
        </div>
        <div className="content container">
            <h2>翻墙VPN</h2>
            <p>我们通过在中国境内的6座主要城市：成都，广州，南京，上海，杭州，北京进行测速。</p>
            <p>测速时，我们选取了阿里云，腾讯云在这些城市部署的云服务器进行测速。服务器我们选择的是Windows server 2019版本，网络带宽在100Mbps以上。</p>
            <p>我们会给予每个VPN三次的连接机会，大部分VPN都会在第一次直接连接上。连接上以后，我们通过speedtest.cn测速平台对不同的VPN进行连接后的测速。我们取测速的平均值作为最终的结果。</p>
            <p>测速过程中的截图如下：</p>
            {/* <Image src="speedtest_aliyun.PNG" alt="阿里云测速" /> */}
            <br />
            {/* <Image src="speedtest_txyun.PNG" alt="腾讯云测速" /> */}
            <br/>
            <h2>回国VPN</h2>
            <p>我们通过部署在全球的AWS（亚马逊云服务器）进行了实地的测速。</p>
            <p>主要包括：日本东京，新加坡，澳大利亚悉尼，美国旧金山，法国巴黎，英国伦敦，意大利米兰，巴西圣保罗。包含了全球4大洲几乎覆盖了全部的华人聚集区域。</p>
            <p>服务器我们同样选择的是Windows server 2019版本。为了保证测速的品质，我们的服务器的网络带宽达到了惊人的5Gbps，下载一部电影只需要1，2秒钟的时间。</p>
            <p>对于所有的回国VPN，我们都开启全局模式进行测试，我们同样使用speedtest.cn作为我们的测速网站，多次测试取平均值。</p>
            <p>测速过程中的截图如下：</p>
            {/* <Image src="speedtest_aws.png" alt="AWS测速" /> */}
            <br/>
            <p>我们不计成本来进行实地的测速也是为了能够给大家带来最为真实可靠的结果。</p>
            <p>VPN这个领域里一直以来都存在大量欺骗，我们希望能够用数据来说话。</p>
        </div>
    </div>
    </Layout>
)

export default SpeedTest